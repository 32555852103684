'use strict'

/**
 * Saves a given value in local storage.
 * @param {string} key - the value's storage key
 * @param {object} val - the value to store
 */
function set (key, val) {
  window.localStorage.setItem(key, JSON.stringify(val))
}

/**
 * Saves each of a given object's properties separately in local storage,
 * using the property name as they key.
 * @param {object} obj - the object whose properties to save
 * @param {string} name - the name under which save the properties
 */
function setAll (obj, name) {
  for (const key of Object.keys(obj)) {
    this.set(`${name}.${key}`, obj[key])
  }
}

/**
 * Returns the value in local storage corresponding to the given key,
 * or a default value if it doesn't exist.
 * @param {string} key - the value's storage key
 * @param {*} def - the default return value if key is not set
 * @returns {*}
 */
function get (key, def = null) {
  return JSON.parse(window.localStorage.getItem(key)) || def
}

/**
 * Returns an object containing all values in local storage whose key begins
 * with the given value followed by a period. If there are no values with such
 * keys, returns an empty object.
 * (Honestly this has terrible performance implications because I loop through
 * every key in localStorage but I can't be bothered to fix this unless
 * somebody notices an issue.)
 * @param {string} name - the object's name (i.e. the key prefix)
 * @returns {object}
 */
function getAll (name) {
  const prefix = name + '.'
  const obj = {}
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i)
    if (key.startsWith(prefix)) {
      const objKey = key.substring(prefix.length)
      obj[objKey] = this.get(key)
    }
  }
  return obj
}

export default { set, setAll, get, getAll }
