<template>
  <svg :viewBox="viewbox" xmlns="http://www.w3.org/2000/svg" class="hive">
    <hex class="center" :letter="center" :height="h" :coords="centerCoords"/>
    <hex v-for="(letter, index) of others" :key="index" :letter="letter" :height="h" :coords="otherCoords[index]"/>
  </svg>
</template>

<script>
import Hex from './Hex.vue'

export default {
  name: 'hive',
  components: {
    Hex
  },
  data () {
    const h = 100
    const w = (3 * h) / (2 * Math.sqrt(3))
    const py = h / 10
    const px = py / (Math.sqrt(3) / 2)
    return {
      h: h,
      viewbox: `0 0 ${((10 / 3) * w) + (2 * px) + 2} ${(3 * h) + (2 * py) + 2}`,
      centerCoords: { x: w + px, y: h + py },
      otherCoords: [
        // y coords need +1 to account for top border
        { x: 0, y: (h + py) / 2 + 1 },
        { x: (w + px), y: 1 },
        { x: 2 * (w + px), y: (h + py) / 2 + 1 },
        { x: 2 * (w + px), y: (3 / 2) * (h + py) + 1 },
        { x: (w + px), y: 2 * (h + py) + 1 },
        { x: 0, y: (3 / 2) * (h + py) + 1 }
      ]
    }
  },
  computed: {
    center: function () {
      return this.$store.getters.center
    },
    others: function () {
      return this.$store.getters.others
    }
  }
}
</script>

<style lang="stylus" scoped>
.hive
  height var(--hiveSize)
  padding var(--px) 0
</style>
