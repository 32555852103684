<template>
  <div class="status-bar">
    <Score @toggle="toggle" :collapsed="collapsed"/>
    <div class="wrapper">
      <div class="collapsible">
        <WordList class="word-list" :collapsed="collapsed"/>
        <Progress class="progress-bar"/>
      </div>
    </div>
  </div>
</template>

<script>
import Score from './status-bar/Score.vue'
import WordList from './status-bar/WordList.vue'
import Progress from './status-bar/Progress.vue'

export default {
  name: 'StatusBar',
  components: {
    Score,
    WordList,
    Progress
  },
  data () {
    return {
      collapsed: true
    }
  },
  computed: {
    reinit: function () {
      return this.$store.state.letters
    }
  },
  watch: {
    reinit: function () {
      this.collapsed = true
    }
  },
  methods: {
    toggle: function () {
      if (this.$store.getters.initialized) {
        this.collapsed = !this.collapsed
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.status-bar
  display flex
  flex-direction column
  margin var(--gutter) var(--gutter) 0
  font-size 1.7rem
  background-color var(--base)
  z-index 1
  position relative

  .wrapper
    position relative
  .collapsible
    position absolute
    top 0
    width 100%
    background-color var(--base)
    border-radius var(--radius)
</style>
