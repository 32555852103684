<template>
  <button @click="$emit('toggle')" class="score" :class="{ skelly: !init }" :style="styles">
    Found: {{ found }} / {{ valid }}
    <span ara-hidden="true" class="sep">|</span>
    Score: {{ score }} / {{ max }}
  </button>
</template>

<script>
export default {
  name: 'Score',
  props: {
    collapsed: Boolean
  },
  computed: {
    init: function () {
      return this.$store.getters.initialized
    },
    found: function () {
      return this.$store.getters.found.length.toLocaleString()
    },
    valid: function () {
      return this.$store.getters.valid.length.toLocaleString()
    },
    score: function () {
      return this.$store.getters.score.toLocaleString()
    },
    max: function () {
      return this.$store.getters.maxScore.toLocaleString()
    },
    styles: function () {
      return {
        '--char': this.collapsed ? "'+'" : "'-'"
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.score
  padding var(--py) var(--px)
  border 1px solid var(--border)
  border-bottom none
  border-radius var(--radius) var(--radius) 0 0
  font-size inherit
  text-align left
  transition .1s linear
  transition-property color, background-color, border-color
  &::after
    content var(--char)
    color var(--border)
    float right
    transition .1s linear color
  &:hover
  &:focus
    outline none
  .sep
    color var(--border)
    margin 0 var(--py)
    transition .1s linear color

// skeleton UI
.skelly
.skelly::after,
.skelly .sep
  color transparent
  background-color var(--skellyBg)
  border-color var(--skellyFg)
</style>
