<template>
  <div id="main">
    <StatusBar/>
    <Alert/>
    <GameArea/>
    <Footer/>
  </div>
</template>

<script>
import StatusBar from './components/StatusBar.vue'
import Alert from './components/Alert.vue'
import GameArea from './components/GameArea.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    StatusBar,
    Alert,
    GameArea,
    Footer
  },
  beforeCreate: async function () {
    // if we have an 'h' param, use that as the hash (otherwise pass null)
    const params = new URLSearchParams(location.search)
    this.$store.dispatch('init', { reinit: false, hash: params.get('h') })
    // remove the hash from url (if present)
    // this prevents us from reinitializing the game state when reloading
    history.replaceState(null, '', location.origin)
  }
}
</script>

<style lang="stylus">
@require 'styles/_vars'
@require 'styles/_reset'

body,
button
  color var(--text)
  background-color var(--base)
</style>

<style lang="stylus" scoped>
#main
  height inherit
  display flex
  flex-direction column
  justify-content space-between
</style>
