<template>
  <div class="alert" :class="{ show: alert.message }">
    <span class="text">{{ alert.message }}</span>
    <button @click="close" class="close">&times;</button>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data () {
    return {
      timeout: 0 // invalid ID; clearTimeout fails silently
    }
  },
  computed: {
    alert: function () {
      return this.$store.state.alert
    },
    reinit: function () {
      return this.$store.state.letters
    }
  },
  watch: {
    alert: function (alert) {
      if (alert.message) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => { this.$store.commit('dismiss') }, 5000)
      }
    },
    reinit: function () {
      this.$store.commit('dismiss')
    }
  },
  methods: {
    close: function () {
      this.$store.commit('dismiss')
    }
  }
}
</script>

<style lang="stylus" scoped>
.alert
  position absolute
  left 50%
  transform translateX(-50%)
  top 25%
  width max-content
  max-width 80vw
  background-color var(--text)
  border 1px solid var(--text)
  border-radius var(--radius)
  opacity .9
  z-index 1
  display none
  justify-content space-between
  &.show
    display flex

  .text
    color var(--base)
    padding var(--py) 0 var(--py) var(--px)
  .close
    font-size 2rem
    background none
    border none
    cursor pointer
    color var(--base)
    padding var(--py) var(--px)
    font-weight bold
    border-radius var(--radius)
</style>
