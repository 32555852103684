<template>
  <div class="game-area">
    <Word/>
    <Hive/>
    <Controls/>
  </div>
</template>

<script>
import Word from './game-area/Word.vue'
import Hive from './game-area/Hive.vue'
import Controls from './game-area/Controls.vue'

export default {
  name: 'GameArea',
  components: {
    Word,
    Hive,
    Controls
  }
}
</script>

<style lang="stylus" scoped>
.game-area
  display flex
  flex-direction column
  align-items center
  justify-content center
  padding-top var(--offset)
</style>
