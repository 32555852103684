import Vue from 'vue'
import Vuex from 'vuex'
import local from '../lib/local'
import _ from '../lib/utils'

Vue.use(Vuex)

/**
 * The game size (i.e. the number of letters in the hive);
 * this is also the number of unique letters in a pangram.
 */
const SIZE = 7

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    word: '', // currently entered word
    found: [], // list of valid words found by the player
    letters: [...Array(SIZE)].map(_ => ''), // current game's letters; center is letter at index 0
    order: [...Array(SIZE - 1)].map((_, i) => i), // order in which to display letters in the hive
    dictionary: [], // list of all words known to the game
    alert: { time: null, message: null } // the alert currently being displayed to the user
  },
  mutations: {
    /* general/initial mutations */
    init (state, newState) {
      state.dictionary = newState.words
      state.letters = newState.letters
      state.found = newState.found
      local.setAll(newState, 'state')
    },
    alert (state, { message }) {
      state.alert = { time: Date.now(), message: message }
    },
    dismiss (state) {
      state.alert = { time: Date.now(), message: null }
    },

    /* hive mutations */
    shuffle (state) {
      // shuffling only changers the order array and leaves the letters array intact;
      // this allows us to change order of the letters without re-computing anything that depends
      // on the letter themselves (most notably the max score)
      state.order = _.shuffle(state.order)
    },

    /* word mutations */
    add (state, { letter }) {
      state.word += letter.toLowerCase()
    },
    remove (state) {
      state.word = state.word.substring(0, state.word.length - 1)
    },
    submit (state, { word }) {
      state.found.push(word)
      local.set('state.found', state.found)
    },
    reset (state) {
      state.word = ''
    }
  },
  actions: {
    /* init actions */
    async init ({ commit }, { reinit, hash } = { reinit: false, hash: null }) {
      // init state to an empty object
      let state = {}
      // get saved state (if any)
      const saved = local.getAll('state')
      // get hash data (if any)
      const hashed = hash ? JSON.parse(Buffer.from(hash, 'base64')) : {}

      // if reinit flag is true, or both saved and hashed objects are empty...
      if (reinit || (_.empty(saved) && _.empty(hashed))) {
        // start new game:
        //  first, load word list
        const list = await fetch('./words.txt')
          .then(response => response.text())
          .catch(err => console.error(err)) // TODO: handle error
        state.words = list.split(/\r?\n/)
        //  then choose a random pangram and save its (shuffled & unique) letters
        const pangram = _.pick(state.words.filter(word => _.pangram(word, SIZE)))
        if (process.env.NODE_ENV === 'development') console.info(`chosen pangram: ${pangram}`)
        state.letters = _.shuffle(_.unique([...pangram]))
        //  finally reset found words
        state.found = []

      // if the hashed state is empty OR thash==he saved and hashed states are for the same game
      } else if (_.empty(hashed) || _.eq(saved, hashed)) {
        // load saved game; this preserves the found word list when saved == hashed
        state = saved

      // else: the saved state is empty OR the saved and hashed games are different
      } else {
        // load hashed game
        state = hashed
        state.found = []
      }

      // save the current game state
      commit('init', state)
      // make sure we reset the initial game state (reset input, shuffle letters)
      commit('reset')
      commit('shuffle')
    },

    /* word actions */
    add ({ state, commit }, { letter }) {
      // if the alert is up, dismiss it
      if (state.alert.message) commit('dismiss')

      if (state.word.length >= 20) {
        commit('reset')
        commit('alert', { message: 'too long' })
      } else {
        commit('add', { letter: letter })
      }
    },
    submit ({ getters, state, commit }) {
      const word = state.word
      // if word is empty, do nothing
      if (word.length === 0) {
        return
      // otherwise go through conditions, throwing error if any isn't met
      } else if (state.found.includes(word)) {
        commit('alert', { message: 'already found' })
      } else if (word.length < 4) {
        commit('alert', { message: 'too short' })
      } else if (!word.includes(getters.initialized ? state.letters[0] : null)) {
        commit('alert', { message: 'missing center letter' })
      } else if (!state.dictionary.includes(word)) {
        commit('alert', { message: 'not in word list' })
      // if everything checks out, submit the word and inform the user
      } else {
        const score = _.score(word, SIZE)
        commit('submit', { word: word })
        commit('alert', { message: `worth ${score} point${score > 1 ? 's' : ''}` })
      }
      // in any case, reset word
      commit('reset')
    }
  },
  getters: {
    initialized: state => {
      // the game is considered intialized iff every element in the letters array
      // is a non-empty string
      return state.letters.reduce((acc, letter) => acc && Boolean(letter), true)
    },
    center: state => {
      return state.letters[0].toUpperCase()
    },
    others: state => {
      const letters = state.letters.slice(1).map(letter => letter.toUpperCase())
      return state.order.map(index => letters[index])
    },
    word: state => {
      return state.word.toUpperCase()
    },
    found: state => {
      return state.found.map(word => {
        return {
          word: word[0].toUpperCase() + word.slice(1),
          score: _.score(word, SIZE),
          pangram: _.pangram(word, SIZE)
        }
      })
    },
    score: state => {
      return state.found.reduce((score, word) => {
        return score + _.score(word, SIZE)
      }, 0)
    },
    valid: state => {
      return state.dictionary
        .filter(word => {
          return word.includes(state.letters[0]) &&
            [...word].filter(char => !state.letters.includes(char)).length === 0
        })
    },
    maxScore: (state, getters) => {
      return getters.valid
        .reduce((score, word) => {
          return score + _.score(word, SIZE)
        }, 0)
    }
  }
})
