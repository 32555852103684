import Vue from 'vue'
import App from './App.vue'
import store from './store'

// register service worker
if ('serviceWorker' in navigator) navigator.serviceWorker.register('./sw.js')
// load vue
new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
