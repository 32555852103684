'use strict'

/**
 * Returns a random integer between [min, max).
 * @param {number} max - the range's upper bound (exclusive)
 * @param {number} min - the range's lower bound (inclusive)
 * @returns {number}
 */
function randint (max, min = 0) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

/**
 * Swaps the values at i & j of the given array and returns the new array.
 * @param {Array} array - the original array
 * @param {number} i - the first element's index
 * @param {number} j - the second element's index
 * @returns {Array}
 */
function swap (array, i, j) {
  const tmp = array[i]
  array[i] = array[j]
  array[j] = tmp

  return array
}

/**
 * Returns a shuffled copy of the given array; implements the Fisher-Yates shuffle.
 * @param {Array} array - the original array
 * @returns {Array}
 */
function shuffle (array) {
  const shuffled = [...array]

  for (let i = array.length - 1; i > 0; i--) {
    const j = randint(i + 1)
    swap(shuffled, i, j)
  }

  return shuffled
}

/**
 * Returns a random value from the given array.
 * @param {Array} array - the array
 * @returns {*}
 */
function pick (array) {
  return array[randint(array.length)]
}

/**
 * Returns a deduped version of the given array.
 * @param {Array} array - the original array
 * @returns {Array}
 */
function unique (array) {
  return array.filter((value, index, arr) => arr.indexOf(value) === index)
}

/**
 * Returns whether a given word is a pangram of a certain size. A word is a pangram of size x iff
 * the number of unique letters in the word is equal to x.
 * @param {string} word - the word being verified
 * @param {number} size - the pangram 'size'
 * @returns {boolean}
 */
function pangram (word, size) {
  return unique([...word]).length === size
}

/**
 * Returns the score granted for a given word and pangram size (see above).
 * Score is calculated as follows:
 *  - four-letter words are worth one point;
 *  - longer words are worth their length in characters;
 *  - pangrams are worth an extra sevent points.
 * @param {string} word - the word for which the score is being calculated
 * @param {number} size - the pangram 'size'
 * @returns {number}
 */
function score (word, size) {
  return (word.length === 4 ? 1 : word.length) + (pangram(word, size) ? size : 0)
}

/**
 * Returns true iff an object is empty (has no properties), false otherwise.
 * @param {Object} obj - the object being checked
 * @returns {boolean}
 */
function empty (obj) {
  return Object.keys(obj).length === 0
}

/**
 * Returns true iff two given states represent the same game, false otherwise.
 * Games are considered equal when:
 *  - they contain the same letters (in any order);
 *  - the center letter is the same;
 *  - the word lists are identical.
 * FIXME: this fails if one word list contains extra non-valid words; these words don't affect the
 *  game (they're filtered out of the valid word list) so shouldn't matter
 * @param {Object} s1 - the first game state being compared
 * @param {Object} s2 - the second game state being compared
 * @returns {boolean}
 */
function eq (s1, s2) {
  const letters = _arrEq(s1.letters, s2.letters)
  const center = (s1.letters.length > 0 && s2.letters.length > 0) &&
    (s1.letters[0] === s2.letters[0])
  const words = _arrEq(s1.words, s2.words)

  return letters && center && words
}

/**
 * Returns true iff two given arrays contain exactly the same elements (in any order),
 * false otherwise. Note that this function only works for arrays of scalar values.
 * FIXME maybe?
 * @param {Array} a1 - the first array being compared
 * @param {Array} a2 - the second array being compared
 * @returns {boolean}
 */
function _arrEq (a1, a2) {
  a1 = [...a1].sort()
  a2 = [...a2].sort()
  return a1.length === a2.length && a1.every((v, i) => v === a2[i])
}

export default { randint, swap, shuffle, pick, unique, pangram, score, empty, eq }
