<template>
  <div class="progress" :class="{ skelly: !init, full: score >= max }" :style="styles">
    <span
      v-for="(marker, index) in markers"
      :key="index"
      class="marker"
      :style="{ left: `${marker.val}%`, '--score': `'${Math.round(marker.val * max / 100)}'` }"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'Progress',
  data () {
    return {
      markers: [
        { name: 'nice', val: 25 },
        { name: 'great', val: 35 },
        { name: 'amazing', val: 50 },
        { name: 'genius', val: 70 },
        { name: 'queen bee', val: 100 }
      ]
    }
  },
  computed: {
    init: function () {
      return this.$store.getters.initialized
    },
    score: function () {
      return this.$store.getters.score
    },
    max: function () {
      return this.$store.getters.maxScore
    },
    styles: function () {
      return {
        '--w': this.init ? Math.round(Math.min(this.score / this.max, 1) * 100) + '%' : 0
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.progress
  position relative
  width 100%
  border 1px solid var(--border)
  border-top none
  border-radius 0 0 var(--radius) var(--radius)

  .marker
    position absolute
    top 0
    border-right 1px solid var(--border)
    height 100%
    &::before
      content var(--score)
      position absolute
      top 0
      right 1rem
      font-size 1.2rem

  &, .marker, .marker::before
    transition .1s linear
    transition-property color, background-color, border-color

  &::before
    content: ''
    display block
    height 1.5rem
    width var(--w)
    border-radius 0 0 0 var(--radius)
    background-color var(--focus)
    transition .2s ease-in-out width
  &.full::before
    border-radius 0 0 var(--radius) var(--radius)

// skeleton UI
.skelly
  background-color var(--skellyBg)
  border-color var(--skellyFg)
  & .marker
    border-right-color var(--skellyFg)
    &::before
      color transparent
</style>
