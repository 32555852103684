<template>
  <g @click="add" class="hex" :transform="translate" :class="{ skelly: !init }">
    <polygon :points="points"/>
    <text :x="this.width / 2" :y="this.height / 2" class="letter">{{ letter }}</text>
  </g>
</template>

<script>
export default {
  name: 'Hex',
  props: {
    letter: String,
    height: Number,
    coords: {
      x: Number,
      y: Number
    }
  },
  data () {
    const th = this.height // total height
    const tw = 2 * this.height / Math.sqrt(3) // total width
    const aw = tw / 4 // 'angle' width
    return {
      width: tw,
      points: `${tw - aw},0 ${tw},${th / 2} ${tw - aw},${th} ${aw},${th} 0,${th / 2} ${aw},0`,
      viewBox: `0 0 ${tw} ${th}`
    }
  },
  computed: {
    init: function () {
      return this.$store.getters.initialized
    },
    translate: function () {
      return `translate(${this.coords.x}, ${this.coords.y})`
    }
  },
  methods: {
    add: function () {
      this.$store.dispatch('add', { letter: this.letter })
    }
  }
}
</script>

<style lang="stylus" scoped>
.hex
  fill var(--focus)
  stroke var(--border)
  user-select none
  cursor pointer
  transition .1s linear
  transition-property fill, stroke
  &.center
    fill var(--accent)

  .letter
    font-size 4.5rem
    text-anchor middle
    dominant-baseline central
    fill var(--text)
    stroke none
    transition .1s linear fill

// skeleton UI
.skelly,
.skelly.center,
  cursor initial
  stroke var(--skellyFg)
  fill var(--skellyBg)
  &,
  .letter
    fill var(--skellyBg)
</style>
