<template>
  <div class="controls">
    <button @click="remove" class="btn" :class="{ skelly: !init }">&larr;</button>
    <button @click="shuffle" class="btn" :class="{ skelly: !init }">&nesear;</button>
    <button @click="submit" class="btn" :class="{ skelly: !init }">&crarr;</button>
  </div>
</template>

<script>
export default {
  name: 'Controls',
  computed: {
    init: function () {
      return this.$store.getters.initialized
    }
  },
  methods: {
    remove: function () {
      this.$store.commit('remove')
    },
    shuffle: function () {
      this.$store.commit('shuffle')
    },
    submit: function () {
      this.$store.dispatch('submit')
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn
  cursor pointer
  margin 0 calc(var(--gutter) / 2)
  font-size 2.5rem
  padding var(--py) calc(1.5 * var(--px))
  border-radius var(--radius)
  border 1px solid var(--border)
  box-shadow var(--radius) var(--radius) 0 0 var(--border)
  transition .1s linear
  transition-property color, background-color, border-color
  &:hover
  &:focus
    outline none
    background-color var(--focus)
  &:active
    box-shadow none
    transform translate(var(--radius), var(--radius))

// skeleton UI
.skelly,
.skelly:hover,
.skelly:focus
  cursor initial
  color transparent
  background-color var(--skellyBg)
  border-color var(--skellyFg)
  box-shadow var(--radius) var(--radius) 0 0 var(--skellyFg)
.skelly:active
  transform none
</style>
