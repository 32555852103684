<template>
  <div class="words" :class="{ skelly: !init, collapsed: collapsed }">
    <ul class="list">
      <li v-for="word in words" :key="word.word" class="word" :class="{ pangram: word.pangram }">
        {{ word.word }}<template v-if="!collapsed"> ({{ word.score }})</template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WordList',
  props: {
    collapsed: Boolean
  },
  computed: {
    init: function () {
      return this.$store.getters.initialized
    },
    words: function () {
      const words = [...this.$store.getters.found]
      return this.collapsed ? words.reverse() : words.sort((a, b) => {
        if (a.word === b.word) return 0
        return (a.word > b.word) ? 1 : -1
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
// wrapper
.words
  width 100%
  max-height 80vh
  overflow-y scroll
  border 1px solid var(--border)
  transition .1s linear
  transition-property background-color, border-color
  // shared
  .list
    margin 0
    padding var(--py) var(--px)
    list-style none
    overflow-x hidden
    text-overflow ellipsis
    white-space nowrap
    &::before
      transition .1s linear color
    &:empty
      color var(--border)
      &::before
        content 'Start entering words to see them here.'
  // collapsed
  &.collapsed
    .list
      .word
        display inline
        &:not(:last-child)::after
          content ', '
          color var(--border)
  // open
  &:not(.collapsed)
    .list:not(:empty)
      padding var(--py) var(--px) 0
      display flex
      flex-wrap wrap
      .word
        width calc(100% / var(--columns))
        padding-bottom var(--py)
        &.pangram
          font-weight bold
          color var(--accent)

// skeleton UI
.skelly
  background-color var(--skellyBg)
  border-color var(--skellyFg)
  .list:empty
    color transparent
</style>
