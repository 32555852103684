<template>
  <footer class="footer" :class="{ skelly: !init }">
    <button @click="reinit" type="button"  class="btn">&olarr;</button>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      show: false
    }
  },
  computed: {
    init: function () {
      return this.$store.getters.initialized
    }
  },
  methods: {
    reinit: function () {
      if (confirm('Do you want get a new word list?')) {
        this.$store.dispatch('init', { reinit: true })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.footer
  display flex
  justify-content center
  font-size 1.2rem
  margin-top var(--gutter)
  padding var(--py) var(--px)
  background-color var(--text)
  color var(--base)
  transition .1s linear
  transition-property background-color

  .btn
    cursor pointer
    font-size 2rem
    color var(--base)
    border 1px solid var(--base)
    border-radius 100%
    background none
    height 3.5rem
    width 3.5rem
    padding 0
    margin 0 calc(var(--gutter) / 2)
    transition .1s linear
    transition-property color, background-color, border-color
    &:hover
    &:focus
      outline none
      color var(--text)
      background-color var(--base)
    &:active
      color var(--base)
      background-color var(--text)

.skelly
.skelly .btn
.skelly .btn:hover
.skelly .btn:focus
.skelly .btn:active
  color transparent
  background-color var(--skellyBg)
  border-color var(--skellyFg)
</style>
