<template>
  <span class="word">
    <span v-for="(letter, index) in word" :key="index"
      class="letter" :class="{ center: letter === center }">{{ letter }}</span>
  </span>
</template>

<script>
export default {
  name: 'Word',
  computed: {
    word: function () {
      return [...this.$store.getters.word]
    },
    center: function () {
      return this.$store.getters.center
    }
  }
}
</script>

<style lang="stylus" scoped>
.word
  font-size var(--wordSize)
  text-align center
  &:empty::before
    content '\A0' // &nbsp;
  .letter
    padding 0 .3rem
.center
  color var(--accent)
</style>
